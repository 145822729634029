import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import styles from "./NavBar.module.css"
import logo from "./img/logo.svg";
import iconUser from "./img/icon-user.svg"
import iconActiveUser from "./img/icon-active-user.svg"
import basketSvg from "./img/icon-cart-empty.svg"
import basketSvgFull from "./img/icon-cart-full.svg"
import {Container} from "react-bootstrap";
import useElementCoordinates from "./hooks/useElementCoordinates";

const NavBar = observer(({ navBarItem, navBarItemClicked, navBarMenu, logoX, setLogoX }) => {
    const { basketStore } = useContext(Context);
    const { userStore } = useContext(Context);
    const logoRef = useRef(null);
    const { productStore } = useContext(Context);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navBarItemClickedIn = (item) => {
        navBarItemClicked(item);
        setIsMenuOpen(false); // Закрыть меню после выбора пункта
    };

    useEffect(() => {
        setLogoX(logoRef?.current.getBoundingClientRect().left);
        // eslint-disable-next-line
    }, [basketStore.basketXY.innerWidth]);

    const basketRef = useRef(null);
    const userRef = useRef(null);

    useElementCoordinates(basketRef, basketStore.setBasketXY.bind(basketStore), basketStore);
    useElementCoordinates(userRef, userStore.setUserMenuXY.bind(userStore), userStore);

    const bpAmount = basketStore.basket.basket_products?.reduce((sum, bp) => sum + bp.amount, 0) || 0;

    useEffect(() => {
        bpAmount || basketStore.setShowBasket(false);
        // eslint-disable-next-line
    }, [bpAmount]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Container fluid className={`px-0 ${styles.headerContainer}`}>
            <div className={styles.navBar}/>
            <div className={styles.navBarP}>
                <div className={styles.logoContainer} style={{ left: logoX }}>
                    <img src={logo} alt="logo" className={styles.logo} onClick={() => navBarItemClickedIn(0)} />
                </div>

                <div ref={logoRef} className={styles.header}>
                    <div className={styles.hamburgerMenu} onClick={toggleMenu}>
                        <span className={styles.hamburgerBar}></span>
                        <span className={styles.hamburgerBar}></span>
                        <span className={styles.hamburgerBar}></span>
                    </div>

                    <div className={`${styles.navbarMenu} ${isMenuOpen ? styles.menuOpen : ''}`}>
                        {navBarMenu.map(n =>
                            <div
                                key={n.name}
                                className={styles.menuItem}
                                style={{
                                    color: n.id === 3 ? 'var(--warning)' : 'var(--text)',
                                    fontWeight: n.id === ~~navBarItem ? '800' : 'normal'
                                }}
                                onClick={() => navBarItemClickedIn(n.id)}
                            >{n.name}
                            </div>
                        )}
                    </div>

                    {productStore.products?.length > 0 && (
                        <div className={styles.user} ref={userRef}>
                            <img src={userStore.user.email ? iconActiveUser : iconUser} alt="User"
                                onMouseOver={() => userStore.setShowUserMenu(true)}
                                onClick={() => userStore.setShowUserMenu(true)}
                            />
                        </div>
                    )}
                    {productStore.products?.length > 0 ? (
                        <div className={styles.basket}>
                            {bpAmount > 0 ? (
                                <div className={styles.amount}
                                    onMouseOver={() => basketStore.setShowBasket(true)}
                                    onClick={() => basketStore.setShowBasket(true)}
                                >
                                    {bpAmount} ks
                                </div>
                            ) : (
                                <div className={styles.amount} />
                            )}
                            <div ref={basketRef} className={styles.cart}>
                                <img src={bpAmount > 0 ? basketSvgFull : basketSvg} alt="Basket Cart"
                                    onMouseOver={() => {
                                        bpAmount > 0 && basketStore.setShowBasket(true)
                                    }}
                                    onClick={() => {
                                        bpAmount > 0 && basketStore.setShowBasket(true)
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div ref={basketRef}></div>
                    )}
                </div>
            </div>
        </Container>
    );
});

export default NavBar;
