import { useEffect } from "react";

export const useContentDimensions = (contentRefCurrent, setIsFooterFixed) => {
    useEffect(() => {
        const handleResize = () => {
            if (contentRefCurrent) {
                const contentHeight = contentRefCurrent.offsetHeight;
                const windowHeight = window.innerHeight;
                setIsFooterFixed(contentHeight < windowHeight);
                //console.log('contentHeight', contentHeight, 'windowHeight', windowHeight);
            }
        };

        window.addEventListener('resize', handleResize, { passive: true });
        window.addEventListener('load', handleResize, { passive: true });
        window.addEventListener('click', handleResize, { passive: true });

        // Initial call to handleResize
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            window.removeEventListener('click', handleResize);
        };
    }, [contentRefCurrent, setIsFooterFixed]);
};