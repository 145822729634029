import {useEffect} from "react";
import {checkConnection} from "../http/activityAPI";

export const useCheckConnection = (setIsConnected,setLoading) => {
    useEffect(() => {
        checkConnection()
            .then(() => {
                setIsConnected(true)
            })
            .catch(() => {
                checkConnection()
                    .then(() => setIsConnected(true))
                    .catch(() => setIsConnected(false))
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}