import { makeAutoObservable } from "mobx";

export default class ConnectionStatusStore {
    constructor() {
        this._isConnected = false;
        makeAutoObservable(this);
    }

    setIsConnected(value) {
        this._isConnected = value;
    }

    get isConnected() {
        return this._isConnected;
    }

    disconnect() {
        this._isConnected = false;
    }
}