import React, { useContext, useState } from 'react';
import { Button, Container, Form, Image, Spinner } from "react-bootstrap";
import styles from "./SignIn.module.css";
import logo from "../components/headerComponent/img/logo.svg";
import alertTriangle from "./images/alert-triangle.svg";
import { useNavigate } from "react-router-dom";
import { Context } from "../index";
import InputPhone from "../components/UI/inupPhoneNumber/InputPhone";
import { updateUser } from "../http/userAPI";
import {observer} from "mobx-react-lite";

const UserProfile = observer(() => {
    const { userStore } = useContext(Context);
    const [pinCode, setPinCode] = useState('');
    const [name, setName] = useState(userStore.user.name);
    const [surname, setSurname] = useState(userStore.user.surname);
    const [email, setEmail] = useState(userStore.user.email);
    const [phone, setPhone] = useState(userStore.user.phone);
    const [newPassword, setNewPassword] = useState('');
    const [loginError, setLoginError] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('name', name || "");
        formData.append('surname', surname || "");
        formData.append('email', email || "");
        formData.append('phone', phone || "");
        updateUser(formData)
            .then(user => {
                userStore.setUser(user);
                userStore.setIsAuth(true);
                setLoginError(false);
            })
            .catch(() => {
                setLoginError(true);
                setErrorMessage("Chyba API");
            })
            .finally(() => {
                setLoading(false);
            });

        navigate(userStore.backTo);
    }

    return (
        <Container className={styles.signinContainer}>
            <div className={styles.signinBox}>
                <span className={styles.close} onClick={() => navigate(userStore.backTo)}>&times;</span>
                <Image src={logo} alt="logo" className={styles.logo} />
                <Form onSubmit={handleSubmit}>
                    {loginError &&
                        <div className={styles.errorBox}>
                            <img src={alertTriangle} alt={"alertTriangle"} />
                            <div className={"errorBox2"}>
                                <h5>Došlo k problému</h5>
                                <p>{errorMessage}</p>
                            </div>
                        </div>
                    }
                    <h1>Profil</h1>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Jméno</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={""}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formSurname">
                        <Form.Label>Příjmení</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={""}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            disabled={true}
                            type="email"
                            placeholder={""}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Telefon</Form.Label>
                        <InputPhone phone={phone} setPhone={setPhone} />
                    </Form.Group>

                    {loading ?
                        <Button variant="primary" type="submit" disabled={loading}>
                            <Spinner animation="border" size="sm" />
                        </Button>
                        :
                        <Button variant="primary" type="submit" className="mt-1">
                            Uložit
                        </Button>
                    }
                </Form>
            </div>
        </Container>
    );
});

export default UserProfile;