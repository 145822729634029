import React from 'react';
import Modal from "react-bootstrap/Modal";
import PrivacyPolicy from "./PrivacyPolicy";

const PrivacyModal = ({show, setShow}) => {
    return (
        <div>
            <Modal fullscreen show={show} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                    <PrivacyPolicy />
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default PrivacyModal;
