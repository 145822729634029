import React, {useState, useContext} from 'react';
import {Form, Button, Container, Image, Spinner} from 'react-bootstrap';
import styles from './SignIn.module.css';
import logo from '../components/headerComponent/img/logo.svg';
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {resetPasswordAPI, resetPasswordEmailAPI} from "../http/userAPI";
import alertTriangle from "./images/alert-triangle.svg";
import {observer} from "mobx-react-lite";
import {validatePassword} from "../utils/validatePassword";

const ResetPassword = observer(() => {
    const [email, setEmail] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const {userStore} = useContext(Context);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const FIRST_STEP = ''; // первый шаг
    const CODE_SENT = 'RS'; // RS - сброс отправлен
    const PASSWORD_CHANGED = 'ok'; // пароль изменен
    const [state, setState] = useState(FIRST_STEP);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        if (state === FIRST_STEP) { // первый шаг - отправка email
            resetPasswordEmailAPI(email)
                .then(() => {
                    setError(false);
                    setErrorMessage('Email byl odeslán'); // RS - сброс отправлен
                    setState(CODE_SENT);
                })
                .catch(() => {
                    setError(true);
                    setErrorMessage('Email nebyl odeslán. Zkontrolujte email');
                    setState(FIRST_STEP);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (state === CODE_SENT) { // RS - сброс отправлен - второй шаг
            const passwordError = validatePassword(newPassword);
            if (passwordError) {
                setErrorMessage(passwordError);
                setLoading(false);
                setError(true);
                return;
            }
            if (newPassword !== confirmPassword) {
                setErrorMessage('Hesla se neshodují');
                setLoading(false);
                setError(true);
                return;
            }
            resetPasswordAPI(pinCode, newPassword)
                .then(user => {
                    userStore.setUser(user);
                    setErrorMessage('ok');
                    setState(PASSWORD_CHANGED);
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                    setErrorMessage('Heslo nebylo změněno. Zkontrolujte kód');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (state === PASSWORD_CHANGED) { // пароль изменен
            setLoading(false);
            navigate(-1);
        }
        setLoading(false);
    };

    const ButtonWithText = () => {
        if (loading) {
            return (
                <Button variant="primary" type="submit" disabled={loading}>
                    <Spinner animation="border" size="sm" />
                </Button>
            );
        }
        switch (state) {
            case FIRST_STEP:
                return (
                    <Button variant="primary" type="submit" disabled={loading}>
                        Obnovit heslo
                    </Button>
                );
                case CODE_SENT:
                return (
                    <Button variant="primary" type="submit" disabled={loading}>
                        Odeslat kód
                    </Button>
                );
            case PASSWORD_CHANGED:
                return (
                    <Button variant="primary" type="submit" disabled={loading}>
                        Heslo aktualizováno
                    </Button>
                );
                default:
                return (
                    <Button variant="primary" type="submit" disabled={loading}>
                        ?
                    </Button>
                );
        }
    };

    return (
        <Container className={styles.signinContainer}>
            <div className={styles.signinBox}>
                <span className={styles.close} onClick={() => navigate(userStore.backTo)}>&times;</span>
                <Image src={logo} alt="logo" className={styles.logo} />
                <div className="d-flex justify-content-between mb-1"></div>

                <Form onSubmit={handleSubmit}>
                    {error &&
                        <div className={styles.errorBox}>
                            <img src={alertTriangle} alt={"alertTriangle"} />
                            <div className={"errorBox2"}>
                                <h5>Došlo k problému</h5>
                                <p>{errorMessage}</p>
                            </div>
                        </div>
                    }
                    <h1>Obnovit heslo</h1>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            autoComplete="off"
                            placeholder="Zadejte váš email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    {state === CODE_SENT &&
                        <>
                            <Form.Group className="mb-3" controlId="formCode">
                                <Form.Label>Kód z emailu</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    value={pinCode}
                                    onChange={(e) => setPinCode(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPasswrd">
                                <Form.Label>Zadejte nové heslo</Form.Label>
                                <Form.Control
                                    type="password"
                                    autoComplete="off"
                                    placeholder="Zadejte nové heslo"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formConfirmPassword">
                                <Form.Label>Potvrzení nového hesla</Form.Label>
                                <Form.Control
                                    type="password"
                                    autoComplete="off"
                                    placeholder="Zadejte nové heslo znovu"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </Form.Group>
                        </>
                    }
                    <ButtonWithText />
                </Form>
            </div>
        </Container>
    );
});

export default ResetPassword;