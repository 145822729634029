import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import MyModal from "../myModalComponents/MyModal";
import BasketProducts from "./BasketProducts";
import styles from "./css/Basket.module.css"

const Basket = observer(({reserveHandler, ...props}) => {
    const {basketStore} = useContext(Context)
    const basket = basketStore.basket
    const fullPrice = basket?.basket_products?.reduce((sum, bp) => sum + bp.price * bp.amount, 0) || 0
    const minHeight = (basketStore?.basketXY?.innerHeight || 0 - basketStore?.basketXY?.top || 0) * 0.5

    const reserveClickHandler = () => {
        reserveHandler(basket)
    }
    //console.log("Basket>>>" + JSON.stringify(basketStore.basket.basket_products, null, 2))

    return (
        <MyModal isOpenModal={basketStore.showBasket}
                 closeModal={() => basketStore.setShowBasket(false)}
                 modalXY={{
                     ...basketStore.basketXY,
                     left: basketStore.basketXY.left - 303,
                     top: basketStore.basketXY.top + 38,
                     height: "toBottom",
                     weight: 310,
                 }}
                 arrowObj={{isArrow: true}}
        >
            <div className={styles.basketCont}>
                <div className={styles.basket} style={{minHeight: minHeight}}>
                    <BasketProducts {...props}/>
                </div>
                <div className={styles.gotoContainer}>
                    {fullPrice > 1 &&
                        <div className={styles.gotoButton}
                             onClick={() => reserveClickHandler()}
                        >
                            Pokračovat
                        </div>
                    }
                </div>
            </div>
        </MyModal>
    )
});

export default Basket
