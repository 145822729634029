import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import CheckoutBasket from "./CheckoutBasket";
import InputPhone from "../UI/inupPhoneNumber/InputPhone";
import {Context} from "../../index";
import {checkEmailAPI, checkToken, updateUser} from "../../http/userAPI";
import {sendVerificationEmail, sendEmail} from "../../http/emailAPI";
import styles from "./CheckoutForm.module.css";
import emailBodyToHtml from "./emailBodyToHtml";
import {reserveBasket} from "../../http/basketAPI";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import CheckoutFormButtons from "./CheckoutFormButtons";
import CheckoutFormModal from "./CheckoutFormModal";

const CheckoutForm = observer(() => {
    const {basketStore} = useContext(Context)
    const {userStore} = useContext(Context)
    const navigate = useNavigate()

    const [surname, setSurname] = useState(userStore.user?.surname || '')
    const [name, setName] = useState(userStore.user?.name || '')
    const [email, setEmail] = useState(userStore.user?.email || '')
    const [phone, setPhone] = useState(userStore.user?.phone || '')
    const [emailError, setEmailError] = useState(false)
    const [userAlreadyExists, setUserAlreadyExists] = useState(false)
    const [loading, setLoading] = useState(false)
    const nameMexLength = 31;
    const [showErrorInfo, setShowErrorInfo] = useState(false)
    const [buttonErrorMessage, setButtonErrorMessage] = useState(null);

    const isUserLoggedIn = (userAlreadyExists && (userStore.isAuth || userStore.user.is_email_verified)) && email === userStore.user.email;
    const canUserBeUpdated = !userAlreadyExists || isUserLoggedIn
    const [errorMessage, setErrorMessage] = useState('')
    const [errorButtonTitle, setErrorButtonTitle] = useState("Пройти регистрайию")
    const [isFooter, setIsFooter] = useState(true)
    const modalTitle = "Důležitá zpráva";
    const goBackTitle = "Vrátit se zpět";
    const [handleGoto, setHandleGoto] = useState(() => {})
    userStore.setBackTo("/checkout") // back to this page after sign in, sign up or email verification

    // current user is in database and logged in

    useEffect(() => {
        setEmail(userStore.user.email || '')
        setName(userStore.user.name || '')
        setSurname(userStore.user.surname || '')
        setPhone(userStore.user.phone || '')
    }, [userStore.user.email, userStore.user.name, userStore.user.surname, userStore.user.phone]);

    const checkName = (v) => {
        if (v.length > nameMexLength) return false
        return true
    }

    const checkEmail = (v) => {
        const mailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        return v?.match(mailRegExp) || false
    }

    const setSurname1 = (v) => {
        v = v.slice(0, nameMexLength)
        checkName(v) && setSurname(v)
    }
    const setName1 = (v) => {
        v = v.slice(0, nameMexLength)
        checkName(v) && setName(v)
    }

    const onChangeEmail = (v) => {
        if (checkEmail(v)) setEmailError(false);
        else setEmailError(true);
        setEmail(v);
        setButtonErrorMessage(null)
    }

    /**
     * Check if email can be updated
     *
     */
    const checkIfCanEmailBeUpdated = async (email) => { // check if email can be updated
        if (!checkEmail(email)) return Promise.reject(false)

        try {
            await checkToken();
        } catch (e) {
            setErrorMessage(`Dokončete registraci. Token je neplatný. ${e}`)
            setErrorButtonTitle("Dokončit registraci")
            setHandleGoto(()=>handleGotoSignIn);  // go to sign in
            setShowErrorInfo(true)
            return Promise.reject(true)
        }

        try {
            const {is_email_verified} = await checkEmailAPI(email)
            setUserAlreadyExists(true) // email exists
            if (userStore.user.email === email && (userStore.isAuth || is_email_verified))
                return Promise.resolve(true)

            if (userStore.user.email !== email) { // email is not the same - must sign in
                setErrorMessage(`Emailová adresa je již u nás zaregistrována. Aby nedošlo k chybě,
                         prosím potvrďte, že tato adresa je vaše - dokončete registraci.`)
                setErrorButtonTitle("Dokončit registraci")
                setHandleGoto(()=>handleGotoSignIn);  // go to sign in
                setShowErrorInfo(true)    // show modal
                return Promise.reject(true)

            } else if (userStore.user.email === email && !userStore.user.is_email_verified) { // a you sure you have access to this email?
                setErrorMessage(`Emailová adresa je již u nás zaregistrována. Aby nedošlo k chybě, prosím potvrďte,
                         že máte k ní přístup. Po kliknutí na tlačítko "Potvrdit email" bude na tuto adresu odeslán potvrzovací email.
                          Dále postupujte podle pokynů v emailu.`)
                setErrorButtonTitle("Potvrdit email")
                setHandleGoto(()=>handleGotoEmailVerification);
                setShowErrorInfo(true)
                return Promise.reject(true)

            } else {
                setErrorMessage(`K bohužel, tato e-mailová adresa je již používána. Zkuste použít jinou.
                         Pokud tato e-mailová adresa patří vám, můžete se zaregistrovat v horním panelu.`)
                setErrorButtonTitle("")
                setHandleGoto(()=>handleCloseModal);
                setShowErrorInfo(true)
                return Promise.reject(false)
            }
        } catch (e) {
            setUserAlreadyExists(false)
            return Promise.resolve(true)
        }
    }

    const onBlurEmail = (v) => {
    }

    const checkoutClickedHandler = () => { // Do reservation
        if (!checkEmail(email)) {
            setEmailError(true)
            setButtonErrorMessage("Zkontrolujte správnost e-mailové adresy")
            return
        }

        const basket = basketStore.basket
        const formData = new FormData()
        formData.append('name', name.slice(0, nameMexLength))
        formData.append('surname', surname.slice(0, nameMexLength))
        formData.append('email', email)
        formData.append('phone', phone)

        setLoading(true)
        checkIfCanEmailBeUpdated(email)
            .then(() => {
                setButtonErrorMessage(null)
                return updateUser(formData)
            })
            .then((data) => {
                userStore.setUser(data)
                basket.user_id = data.id
            })
            .then(() => {
                return reserveBasket(basket.user_id)
            })
            .then(() => {
                const html = emailBodyToHtml(basket, userStore.user);
                basketStore.setBasket({user_id: basket.user_id, basket_products: []})
                const fd = new FormData()
                fd.append("html", html)
                //setShowModalTest(true) // test
                return sendEmail(fd)
            })
            .then(() => {
                //console.log("Letter has sent successfully")
                navigate('/thanks')
            })
            .catch(e => {
                setButtonErrorMessage("Rezervace se nezdařila. Zkuste to prosím znovu.");
            })
            .finally(() => setLoading(false))
    }

    const handleCloseModal = () => {
        setShowErrorInfo(false)
    }
    const handleGotoSignIn = () => {
        setShowErrorInfo(false)
        navigate('/signIn')
    }
    const handleGotoEmailVerification = () => {
        sendVerificationEmail(email)
            .then(() => {
                setErrorMessage(`Email byl odeslán na adresu ${email}. Otevřete email a postupujte podle pokynů.`)
                setErrorButtonTitle("")
                setHandleGoto(()=>{});
                setShowErrorInfo(true)
                setIsFooter(true)
                setButtonErrorMessage(null)
            })
            .catch((e) => {
                setShowErrorInfo(false)
                alert(`Email verification error: ${e.message}`)
            })

    }

    return (<div className={styles.cont}>
        <CheckoutFormModal show={showErrorInfo}
                           handleClose={handleCloseModal}
                           handleGoto={handleGoto}
                           errorMessage={errorMessage}
                           modalTitle={modalTitle}
                           buttonGotoTitle={errorButtonTitle}
                           buttonGoBackTitle={goBackTitle}
                           isFooter={isFooter}
        />
        <Row>
            <Col sm={12} md={8}>
                <Container className={`px-md-0 ${styles.title}`}>Kontaktní informace
                </Container>
                <Container fluid className={`px-md-0 ${styles.cont1}`}>
                    <div className={styles.textCont}>
                        <div className={styles.text}>Příjmení</div>
                        <input className={styles.input}
                               value={surname}
                               onChange={e => setSurname1(e.target.value)}/>

                        <div className={styles.text}>Jméno</div>
                        <input className={styles.input}
                               value={name} onChange={e => setName1(e.target.value)}/>

                        <div className={styles.text}>E-mail</div>
                        <input
                            className={emailError || !isUserLoggedIn ? `${styles.input} ${styles.error}` : `${styles.input}`}
                            value={email}
                            type="email"
                            onChange={e => onChangeEmail(e.target.value)}
                            onBlur={e => onBlurEmail(e.target.value)}
                        />
                        {emailError ? <div className={styles.emailErrTxt}>Zkontrolujte správnost e-mailové
                            adresy</div> : !canUserBeUpdated &&
                            <div className={styles.emailErrTxt}>E-mail je již zaregistrován.</div>}
                        <div className={styles.text}>Mobil</div>

                        <InputPhone phone={phone} setPhone={setPhone}/>

                        <div className={styles.text2}>
                            Dokončením rezervace potvrzuji, že jsem se seznámil/a
                            se Všeobecnými obchodními podmínkami, porozuměl/a jsem jejich obsahu a souhlasím s nimi.
                        </div>
                        <div className={styles.text3}>
                            Informace o <span>zpracování osobních údajů.</span>
                        </div>
                    </div>
                </Container>
                {window.innerWidth > 768 && <Container fluid className={`px-md-0 mb-3 mb-md-0 ${styles.btns}`}>
                    <CheckoutFormButtons
                        checkoutClickedHandler={checkoutClickedHandler}
                        loading={loading}
                        errorMessage={buttonErrorMessage}
                    />
                </Container>}
            </Col>

            <Col sm={12} md={4} className="px-md-0">
                <Container fluid className={`pe-md-3 ${styles.cont2}`}>
                    <CheckoutBasket productClickHandler={() => {
                    }}/>
                </Container>
                {window.innerWidth <= 768 && <div className={styles.btns_sm}>
                    <CheckoutFormButtons
                        checkoutClickedHandler={checkoutClickedHandler}
                        loading={loading}
                        errorMessage={buttonErrorMessage}
                    />
                </div>}
            </Col>
        </Row>
    </div>);
});

export default CheckoutForm;