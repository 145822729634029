import {$authHost, $host} from "./index";

export const fetchOneBasket = async (user_id) => {
    if (!user_id) return null
    const {data} = await $host.get('api/basket/user/' + user_id)
    return data
}
export const updateBasket = async (basket) => {
    const {data} = await $authHost.post('api/basket/update/', basket)
    return data
}
export const reserveBasket = async (user_id) => {
    const {data} = await $authHost.post('api/basket/reserve/' + user_id)
    return data
}

export const updateBasketAmount = async (dataIn) => {
    const {data} = await $authHost.post('api/basket/amount/', dataIn)
    return data
}

export const moveBasketProducts = async (oldUserId,userId) => {
    const {data} = await $authHost.post('api/basket/moveBasketProducts', {oldUserId,userId})
    return data
}
