export const validatePassword = (password) => {
    const minLength = 8;
    if (password.length < minLength) {
        return `Heslo musí obsahovat alespoň ${minLength} znaků.`;
    }

    const hasUpperCase = /[A-Z]/.test(password);
    if (!hasUpperCase) {
        return "Heslo musí obsahovat alespoň jedno velké písmeno.";
    }

    const hasLowerCase = /[a-z]/.test(password);
    if (!hasLowerCase) {
        return "Heslo musí obsahovat alespoň jedno malé písmeno.";
    }

    const hasNumber = /[0-9]/.test(password);
    if (!hasNumber) {
        return "Heslo musí obsahovat alespoň jednu číslici.";
    }

    /*
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    if (!hasSpecialChar) {
        return "Heslo musí obsahovat alespoň jeden speciální znak.";
    }*/
    return ""; // Пустая строка означает, что пароль валидный
};