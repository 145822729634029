import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAdminPanelVisible = false
        this._isAdmin = false
        this._isAuth = false
        this._defaultUser = {surname:'',name:'',phone:'',email:'',is_email_verified:false}
        this._user = this._defaultUser
        this._showUserMenu = false;
        this._userMenuXY = {height: 325, width: 400, top: 20, left: 20,};
        this._backTo = '/' // back to page

        makeAutoObservable(this)
    }

    setBackTo(backTo) {
        this._backTo = backTo
    }
    get backTo() {
        return this._backTo
    }

    setDefaultUser(){
        this._user = this._defaultUser
    }
    setUserMenuXY(userMenuXY) {
        this._userMenuXY = userMenuXY
    }
    get userMenuXY() {
        return this._userMenuXY
    }

    setShowUserMenu(bool){
        this._showUserMenu = bool
    }
    get showUserMenu(){
        return this._showUserMenu
    }

    setIsAdminPanelVisible(bool) {
        this._isAdminPanelVisible = bool
    }
    setIsAdmin(bool) {
        this._isAdmin = bool
    }
    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }

    get isAdminPanelVisible() {
        return this._isAdminPanelVisible
    }
    get isAdmin() {
        return this._isAdmin
    }
    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
}
