import {$authHost, $host} from "./index";


export const sendEmail = async (html) => {
    const {data} = await $authHost.post('api/email/send',html)
    return data
}

export const emailVerification = async (emailVerificationToken) => {
    const {data} = await $host.get('api/email/emailVerification/'+ emailVerificationToken)
    return data
}
export const sendVerificationEmail = async (email) => {
    const {data} = await $host.post('api/email/verificationToken/set', {email})
    return data
}

