import React, {useContext, useEffect} from 'react';
import HdrCarousel from "../headerComponent/HdrCarousel";
import {Context} from "../../index";
import {getCSSVariableInt} from "../../utils/getCSSVariable";
import {observer} from "mobx-react-lite";
import {Container} from "react-bootstrap";
import iconInst from "../shopComponents/img/instagramIcon.svg"
import background from "./img/brown-wood.jpeg"


const NotConnectedToApi = observer(() => {

    const {basketStore} = useContext(Context)

    basketStore.setBasketXYWindow({
        w: window.innerWidth,
        h: window.innerHeight
    });
    useEffect(() => {
        const onResize = () => {
            basketStore.setBasketXYWindow({
                w: window.innerWidth,
                h: window.innerHeight
            });
        }
        window.addEventListener('resize', onResize, { passive: true })
        return () => {
            window.removeEventListener('resize', onResize);
        }
        // eslint-disable-next-line
    }, []);

    const height = basketStore.basketXY.innerHeight - getCSSVariableInt("--carousel-h")

    return (
        <div>
            <HdrCarousel/>
            {/*<div style={{backgroundImage:`url${background}`,*/}
            <div style={{backgroundImage:`url(${background})`,
                height}}>
                <Container fluid className="d-flex justify-content-center pt-3">
                    <h1 style={{alignContent:'center',color:'var(--primary)'}}>Webové stránky ve výstavbě</h1>
                </Container>
                <div className="d-flex justify-content-center pt-5" style={{color:'var(--primary)'}}>
                    <h1>Under construction</h1>
                </div>
            </div>
            <a style={{position: 'fixed', right: 50, bottom: 30}}
               href='https://instagram.com/zivychleb?igshid=NGk2azQ2aDU2czB6&utm_source=qr'
               rel="noreferrer"
               target="_blank"
            >
                <img src={iconInst} alt={'Instagram'}/>
            </a>
        </div>
    );
});

export default NotConnectedToApi;