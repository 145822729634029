import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CheckoutFormModal = ({show,handleClose,handleGoto,errorMessage,buttonGotoTitle,buttonGoBackTitle,modalTitle,isFooter}) => {
    return (
        <>
            <Modal show={show} onHide={handleClose}
                   variant="info"
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                { isFooter &&
                    <Modal.Footer>
                        {buttonGotoTitle !== '' &&
                            <Button variant="primary" onClick={()=>handleGoto()}>
                            {buttonGotoTitle}
                            </Button>
                        }
                        <Button variant="success" onClick={()=>handleClose()}>
                            {buttonGoBackTitle}
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </>
    );
};

export default CheckoutFormModal;