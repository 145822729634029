import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Container} from "react-bootstrap";
import styles from "./emailVerification.module.css";
import {emailVerification} from "../../http/emailAPI";

const EmailVerification = () => {
    const [isVerified, setIsVerified] = useState(false)
    const [loading, setLoading] = useState(true)
    const {verificationToken} = useParams()

    //console.log("verificationToken>>>" + verificationToken)
    useEffect(() => {
        emailVerification(verificationToken)
            .then(data => {
                setIsVerified(true)
            })
            .catch(e => {
                setIsVerified(false)
                console.log("Email verification error: " + e)
            })
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.cont}>
            {
                loading ? <Container fluid className="px-md-2">
                        <h1 className={styles.title}>Probíhá ověřování...</h1>
                    </Container>
                    :

                    isVerified ?
                        <Container fluid className="px-md-2">
                            <h1 className={styles.title}>Email byl úspěšně ověřen!</h1>
                            <p className={styles.text}>
                                Děkujeme, váš email byl úspěšně ověřen. Nyní můžete plně využívat
                                naše služby.</p>
                            <p className={styles.text}>Pokud máte nějaké otázky nebo potřebujete pomoc, neváhejte nás
                                kontaktovat.</p>
                        </Container>
                        :

                        <Container fluid className="px-md-2">
                            <h1 className={styles.title}>Potvrzení neproběhlo</h1>
                            <p className={styles.text}>
                                Omlouváme se, ale váš potvrzovací odkaz je neplatný nebo vypršel. Je možné, že jste na odkaz klikli vícekrát a váš email již byl potvrzen. Prosím, zkuste znovu potvrdit svůj email.
                            </p>
                            <p className={styles.text}>Pokud máte nějaké otázky nebo potřebujete pomoc, neváhejte nás kontaktovat.</p>
                        </Container>
            }

        </div>
    );
};

export default EmailVerification;