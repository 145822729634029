import React, { useContext, useState } from 'react';
import styles from './SignIn.module.css';
import logo from '../components/headerComponent/img/logo.svg';
import {Form, Image, Container, Button, Spinner} from 'react-bootstrap';
import {Context} from "../index";
import {login} from "../http/userAPI";
import alertTriangle from "./images/alert-triangle.svg";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {loadUserBasket} from "../hooks/useCheckBasketActivity";
import {moveBasketProducts} from "../http/basketAPI";

const SignIn = observer(() => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {userStore} = useContext(Context);
    const [loginError, setLoginError] = useState(false);
    const navigate = useNavigate();
    const {basketStore} = useContext(Context);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let userOld = userStore.user;
        let userNew = {};
        try {
            const user = await login(email, password);
            userNew = user;
            userStore.setUser(user);
            userStore.setIsAuth(true);
            setLoginError(false);
            if (userOld?.id && userOld.id !== user.id) {
                await moveBasketProducts(userOld.id, user.id);
            }
            basketStore.setBasket({ ...basketStore.basket, user_id: userNew.id });
            await loadUserBasket(basketStore, loading);
            navigate(userStore.backTo);
        } catch (e) {
            setLoginError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className={styles.signinContainer}>
            <div className={styles.signinBox}>
                <span className={styles.close} onClick={() => navigate(userStore.backTo)}>&times;</span>
                <Image src={logo} alt="logo" className={styles.logo} />
                <Form className="ps-0" onSubmit={handleSubmit}>
                    {loginError && (
                        <div className={styles.errorBox}>
                            <img src={alertTriangle} alt={"alertTriangle"} />
                            <div className={"errorBox2"}>
                                <h5>Došlo k problému</h5>
                                <p>Vaše heslo je nesprávné</p>
                            </div>
                        </div>
                    )}

                    <h1>Přihlásit se</h1>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            autoComplete="username"
                            placeholder="Zadejte váš email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Heslo</Form.Label>
                        <Form.Control
                            type="password"
                            autoComplete="current-password"
                            placeholder="Zadejte vaše heslo"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            Zapomněli jste heslo? <a href="./resetPassword">Obnovit</a>
                        </Form.Text>
                    </Form.Group>
                    {loading ? (
                        <Button variant="primary" type="submit" disabled={loading}>
                            <Spinner animation="border" size="sm" />
                        </Button>
                    ) : (
                        <Button variant="primary" type="submit">
                            Přihlásit se
                        </Button>
                    )}
                    <Form.Text className="text-muted">
                        Nemáte účet? <a href="/signUp">Zaregistrovat se</a>
                    </Form.Text>
                </Form>
            </div>
        </Container>
    );
});

export default SignIn;