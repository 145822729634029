import {useEffect} from "react";
import {getContentActivityAPI} from "../http/activityAPI";
import {fetchTypes} from "../http/typeAPI";
import {fetchAdditives} from "../http/addtiveAPI";
import {fetchAllergens} from "../http/allergenAPI";
import {fetchIntervals} from "../http/intervalAPI";
import {fetchProductionAll} from "../http/productionAPI";
import {infoStore, productionStore} from "../index";
import {fetchBigProducts} from "../http/productAPI";
import {fetchQualities} from "../http/qualityAPI";
import {fetchFirm} from "../http/firmAPI";


export const loadDirectories = async (productStore,handleError) => {
    try {
        const [types, additives, allergens, intervals, firm] = await Promise.all([
            fetchTypes(),
            fetchAdditives(),
            fetchAllergens(),
            fetchIntervals(),
            fetchFirm()
        ]);
        productStore.setTypes(types);
        productStore.setAdditives(additives);
        productStore.setAllergens(allergens);
        infoStore.setIntervals(intervals);
        infoStore.setFirm(firm);
    } catch (error) {
        handleError(error);
    }
};
export const loadProductions = (productStore, setLoading,handleError) => {
    fetchProductionAll()
        .then(productions => productionStore.setProductions(productions))
        .then(() => fetchBigProducts())
        .then(products => {
            productStore.setProducts(products.filter(p => p.status === "ShowInTheShop"));
        })
        .catch(e => handleError(e))
        .finally(() => setLoading ? setLoading(false) : {})
}

export const loadQualities = (productStore, setLoadingQualities,handleError) => {
    fetchQualities()
        .then((data) => {
            productStore.setQualities(data)
        })
        .catch(e => handleError(e))
        .finally(() => setLoadingQualities(false))
}


export const useLoadAll = (callBack,handleError) => {
    //eslint-disable-next-line
    useEffect(() => callBack(), [])
    useEffect(() => {
        const i = setInterval(
            () => {
                getContentActivityAPI()
                    .then(isConnected => {
                        isConnected && callBack()
                    })
                    .catch(e => {
                        clearInterval(i)
                        handleError(e)
                    })
            }, 1000 * (process.env.REACT_APP_SCREEN_UPDATE_REQUEST_SEC || 30) // 30 sec
        )
        return () => clearInterval(i)
        //eslint-disable-next-line
    }, [])
}
