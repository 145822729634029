import { useEffect, useCallback } from 'react';

const useElementCoordinates = (elementRef, updateCoordinates, store) => {
    const updateXY = useCallback(() => {
        const el = elementRef.current;
        updateCoordinates(el ? {
            left: el?.getBoundingClientRect().left || 0,
            top: el?.getBoundingClientRect().top || 0,
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            scrollY: window.scrollY
        } : {});
    }, [elementRef, updateCoordinates]);

    useEffect(() => {
            const observer = new ResizeObserver(updateXY);

            if (elementRef.current) {
                observer.observe(elementRef.current);
            }

            const handleResize = () => {
                updateXY();
                if (store.setBasketXYWindow) {
                    store.setBasketXYWindow({
                        w: window.innerWidth,
                        h: window.innerHeight
                    });
                }
            };

            window.addEventListener('resize', handleResize, { passive: true });
            window.addEventListener('scroll', handleResize, { passive: true });

            // Initial update
            updateXY();

            return () => {
                if (elementRef.current) {
                    observer.unobserve(elementRef.current);
                }

                window.removeEventListener('resize', handleResize);
                window.removeEventListener('scroll', handleResize);
            };
            // eslint-disable-next-line
        }, []);

};

export default useElementCoordinates;