import React, {useContext, useState} from 'react';
import {Form, Button, Container, Image, Spinner} from 'react-bootstrap';
import styles from './SignIn.module.css';
import logo from '../components/headerComponent/img/logo.svg';
import {checkEmailAPI, signUpAPI} from "../http/userAPI";
import alertTriangle from "./images/alert-triangle.svg";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {validatePassword} from "../utils/validatePassword";
import {sendVerificationEmail} from "../http/emailAPI";
import {observer} from "mobx-react-lite";


const SignUp = observer(() => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const { userStore } = useContext(Context);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const FIRST_STEP = ''; // первый шаг
    const EMAIL_SENT = 'ES'; // ES - email отправлен
    const SIGNUP_COMPLETE = 'ok'; // регистрация завершена
    const [state, setState] = useState(FIRST_STEP);

    const handleSubmit = (event) => {
        event.preventDefault();
        const passwordError = validatePassword(password);
        if (passwordError) {
            setErrorMessage(passwordError);
            setError(true);
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage('Hesla se neshodují');
            setError(true);
            return;
        }
        setLoading(true);
        signUpAPI(email, password)
            .then((user) => {
                userStore.setUser(user);
                return sendVerificationEmail(email);
            })
            .then(() => {
                setError(false);
                setErrorMessage('Verifikační email byl odeslán. Prosím, zkontrolujte vaši schránku.');
                setState(EMAIL_SENT);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setErrorMessage('Chyba při registraci nebo odesílání verifikačního emailu');
                setLoading(false);
            });
    };

    const handleVerification = (event) => {
        event.preventDefault();
        checkEmailAPI(email)
            .then((data) => {
                const { is_email_verified } = data;
                if (is_email_verified) {
                    setState(SIGNUP_COMPLETE);
                    navigate(userStore.backTo);
                } else {
                    throw new Error('Email nebyl ověřen. Zkontrolujte email');
                }
            })
            .catch(() => {
                setError(true);
                setErrorMessage('Email nebyl ověřen. Zkontrolujte email');
            });
    };

    const ButtonWithText = () => {
        if (loading) {
            return (
                <Button variant="primary" type="submit" disabled={loading}>
                    <Spinner animation="border" size="sm" />
                </Button>
            );
        }
        switch (state) {
            case FIRST_STEP:
                return (
                    <Button variant="primary" type="submit" className="w-100">
                        Registrovat
                    </Button>
                );
            case EMAIL_SENT:
                return (
                    <Button variant="primary" type="submit" className="w-100">
                        Potvrdil jsem
                    </Button>
                );
            case SIGNUP_COMPLETE:
                return (
                    <Button variant="primary" disabled className="w-100">
                        Registrace dokončena
                    </Button>
                );
            default:
                return (
                    <Button variant="primary" type="submit" className="w-100">
                        ?
                    </Button>
                );
        }
    };

    return (
        <Container className={styles.signinContainer}>
            <div className={styles.signinBox}>
                <span className={styles.close} onClick={() => navigate(userStore.backTo)}>&times;</span>
                <Image src={logo} alt="logo" className={styles.logo} />
                {error && (
                    <div className={styles.errorBox}>
                        <img src={alertTriangle} alt={"alertTriangle"} />
                        <div className={"errorBox2"}>
                            <h5>Došlo k problému</h5>
                            <p>{errorMessage}</p>
                        </div>
                    </div>
                )}
                {state === EMAIL_SENT && (
                    <Form onSubmit={handleVerification} className="w-100">
                        <h5>Registrace úspěšná</h5>
                        <Form.Group className={styles.successBox}>
                            <Form.Text>
                                Na vaši emailovou adresu byl odeslán email pro potvrzení adresy. Prosím, otevřete email a podle pokynů potvrďte svou emailovou adresu.
                            </Form.Text>
                        </Form.Group>
                        <ButtonWithText />
                    </Form>
                )}
                {state !== EMAIL_SENT && (
                    <Form onSubmit={handleSubmit}>
                        <h1>Registrace</h1>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                autoComplete="off"
                                placeholder="Zadejte váš email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Heslo</Form.Label>
                            <Form.Control
                                type="password"
                                autoComplete="off"
                                placeholder="Zadejte vaše heslo"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formConfirmPassword">
                            <Form.Label>Potvrzení hesla</Form.Label>
                            <Form.Control
                                type="password"
                                autoComplete="off"
                                placeholder="Zadejte vaše heslo znovu"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Group>

                        <ButtonWithText />
                    </Form>
                )}
            </div>
        </Container>
    );
});

export default SignUp;