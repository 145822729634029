import React, {useContext, useState} from 'react';
import {Context} from "./index";
import Shop from "./pages/Shop";
import {Spinner} from "react-bootstrap";
import {useShadowLogin} from "./http/userAPI";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import NotConnectedToApi from "./components/notConnectedComponents/notConnectedToAPI";
import {useCheckConnection} from "./hooks/useCheckConnection";
import SignIn from "./pages/SignIn";
import {LanguageProvider} from "./LanguageContext";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import UserProfile from "./pages/UserProfile";
import {observer} from "mobx-react-lite";

const App = observer(() => {
    const {userStore} = useContext(Context)
    const {basketStore} = useContext(Context)
    const {connectionStatusStore} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [loadingA, setLoadingA] = useState(false)

    useCheckConnection(b => connectionStatusStore.setIsConnected(b),setLoading)
    useShadowLogin(userStore, basketStore, setLoadingA)
    if (loading) {
        return (<Spinner animation={"grow"}/>)
    }
    if (!connectionStatusStore.isConnected) {
        return (<NotConnectedToApi/>)
    }
    if (loadingA) {
        return (<Spinner animation={"grow"}/>)
    }


    return (
        <LanguageProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={'/*'} element={<Shop/>}/>
                    <Route path={'resetPassword'} element={<ResetPassword/>}/>
                    <Route path={'SignIn'} element={<SignIn/>}/>
                    <Route path={'signUp'} element={<SignUp/>}/>
                    <Route path={'userProfile'} element={<UserProfile/>}/>
                    <Route path={'*'} element={<Navigate to={'shop'}/>}/>
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    );
});
export default App;
