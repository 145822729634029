import React, {useRef} from 'react';
import {Container, Spinner} from "react-bootstrap";
import styles from "./CheckoutForm.module.css";
import {useNavigate} from "react-router-dom";

const CheckoutFormButtons = ({loading,checkoutClickedHandler,errorMessage}) => {
    const navigate = useNavigate()
    const target = useRef(null);

    return (
        <Container fluid className={`px-md-0 mb-3 mb-md-0 ${styles.buttons}`}>
            <div className={styles.goBack}
                 onClick={() => navigate('/')}
            >
                Zpět k vyběru
            </div>
            {loading ?
                <div className={styles.gotoButton}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                </div>
                :
                <div className="d-block">
                    <div className={styles.gotoButton} onClick={checkoutClickedHandler} ref={target}>
                        Reservovat
                    </div>
                    {errorMessage &&
                        <div className={styles.emailErrTxt}>
                            {errorMessage}
                        </div>
                    }
                </div>
            }
        </Container>
    );
};

export default CheckoutFormButtons;