import React, {useContext, useEffect} from 'react';
import styles from "./ThanksForm.module.css";
import {Col, Container, Row} from "react-bootstrap";
import GoogleMapHTML from "../mapComponents/GoogleMapHTML";
import {Context, infoStore} from "../../index";
import {observer} from "mobx-react-lite";

const ThanksForm = observer(() => {
    const {basketStore} = useContext(Context)
    const {userStore} = useContext(Context)
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'instant'});
    },[])
    return (
        <div className={styles.cont}>
            <Container fluid className="px-md-0">
                <Row>
                    <Col sm={12} md={8} >
                        <div className={styles.title}>Děkujeme!
                        </div>

                        <Container fluid className={styles.text}>
                            <p>Vaše zboží bylo úspěšně rezervováno na prodejně pod č.{basketStore.basketPrev.id}.
                            </p>
                            <p>Souhrn rezervace jsme právě poslali na {`${userStore?.user?.email}`}.</p>
                            <p>Rezervováné potraviny si vyzvedněte přesně ve Vámi vybraném čase.</p>
                        </Container>

                    </Col>
                    <Col sm={12} md={4} className="my-3">
                        <Container fluid className={`px-md-0 ${styles.col2Rectangle}`}>
                            <Container fluid className={`px-md-0 ${styles.googleMap}`}>
                                <GoogleMapHTML width={300} height={240}/>
                            </Container>
                            <div className={styles.c22}>
                                <div className={styles.c221}>
                                    Adresa prodejny
                                </div>
                                <div className={styles.c222}>
                                    {infoStore.firm.name}
                                </div>
                                <div className={styles.c223} style={{paddingBottom: "16px"}}>
                                    {infoStore.firm.address.street}, {infoStore.firm.address.city}
                                </div>
                                <div className={styles.c224}>
                                    Otevírací doba
                                </div>
                                <div className={styles.c223}>
                                    {infoStore.openDays}
                                </div>
                                <div className={styles.c223}>
                                    {infoStore.openHours}
                                </div>
                                <div style={{height: 30}}/>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
});

export default ThanksForm;