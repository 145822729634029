import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";
import {useEffect} from "react";

export const updateUser = async (values) => {
    const {data} = await $authHost.post('api/user/update/', values)
    localStorage.setItem('token', data.token)
    return data.user

}
const shadowLogin = async () => {
    const {data} = await $authHost.post('api/user/shadowLogin')
    localStorage.setItem('token', data.token)
    return data.user
}
export const createUser = async () => {
    const {data} = await $host.get('api/user/createUser')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const shadowLoginSetUser = (userStore, basketStore, setLoading) => {
    shadowLogin()
        .then(data => {
            userStore.setUser(data)
            basketStore.setBasket({...basketStore.basket, user_id: data.id})
        })
        .catch(() => {
            basketStore.setDefaultBasket();
        })
        .finally(() => {
            setLoading(false)
        })
}

export const useShadowLogin = (userStore, basketStore, setLoading) => {
    //console.log(">>>"+process.env.REACT_APP_API_URL)
    useEffect(() => {
        shadowLoginSetUser(userStore, basketStore, setLoading)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
export const login = async (email, password) => {
    const {data} = await $host.post('api/user/login', {email, password})
    localStorage.setItem('token', data.token)
    return data.user
}

export const checkEmailAPI = async (email) => {
    const {data} = await $host.post('api/user/checkEmail', {email})
    return data
}
export const checkToken = async () => {
    const {data} = await $authHost.get('api/user/checkToken')
    localStorage.setItem('token', data.token)
    jwt_decode(data.token)
}

export const resetPasswordEmailAPI = async (email) => {
    const {data} = await $host.post('api/user/resetPasswordEmail', {email})
    return data
}
export const resetPasswordAPI = async (pinCode, newPassword) => {
    const {data} = await $host.post('api/user/resetPassword', {pinCode, newPassword})
    return data
}

export const signUpAPI = async (email, password) => {
    const {data} = await $host.post('api/user/signUp', {email, password})
    return data
}
