import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import MyModal from "../myModalComponents/MyModal";
import styles from "./User.module.css"
import {useNavigate} from "react-router-dom";

const UserMenu = observer(() => {
    const {userStore} = useContext(Context)
    const {basketStore} = useContext(Context)
    const user = userStore.user
    const navigate = useNavigate()

    const signOut = () => {
        userStore.setShowUserMenu(false)
        localStorage.removeItem("token");
        userStore.setDefaultUser()
        basketStore.setDefaultBasket()
        navigate("/")
    }

    const modalWidth = 260

    return (
        <MyModal isOpenModal={userStore.showUserMenu}
                 modalXY={{...userStore.userMenuXY,
                     height: "auto",
                     heightBody: "auto",
                     width: modalWidth,
                     left: userStore.userMenuXY.left - modalWidth + modalWidth/7,
                     top: userStore.userMenuXY.top + 55,
                 }}

                 closeModal={() => userStore.setShowUserMenu(false)}
                 arrowObj={{isArrow: true}}
        >
            <div className={styles.userCont}>
                {user.email ?
                    <>
                        <div className={styles.userTitle}>
                            <div className={styles.userName}>
                                {user?.name || ''} {user?.surname || ''}
                            </div>
                            <div className={styles.userName}>{user.email || ""}</div>
                        </div>
                        <div className={styles.userBox}>
                            <p className={styles.userMenu} onClick={() => navigate("/userProfile")}>Můj profil</p>
                            <p className={styles.userMenu} onClick={() => signOut()}>Odhlásit se</p>
                        </div>
                    </>
                    :
                    <div className={styles.userBox}>
                        <p className={styles.userMenu} onClick={() => navigate("/signIn")}>Přihlásit se</p>
                    </div>

                }

            </div>
        </MyModal>
    )
});

export default UserMenu
