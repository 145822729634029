import React, {useContext, useState} from 'react';
import styles from './Contacts.module.css'
import GoogleMapHTML from "../../mapComponents/GoogleMapHTML";
import iconAddress from "../img/icon-adress.png"
import iconEmail from "../img/icon-mail.png"
import {sendEmail} from "../../../http/emailAPI";
import {observer} from "mobx-react-lite";
import {Context, infoStore} from "../../../index";
import {Container, Spinner} from "react-bootstrap";

const Contacts = observer(() => {
    const {userStore} = useContext(Context)
    const [name, setName] = useState(userStore.user?.name || '')
    const [email, setEmail] = useState(userStore.user?.email || '')
    const [textMessage, setTextMessage] = useState("")
    const errorTextAddress = 'Zkontrolujte prosím e-mailovou adresu.'
    const errorTextFields = 'Prosím, vyplňte všechna pole.'
    const errorTextTryTomorrow = 'Dnes příliš mnoho volání na technickou podporu. Zkus to znovu zítra. Děkujeme.'
    const errorTextTooOften = 'Příliš často volá technickou podporu. Zkuste to znovu za minutu. Děkujeme.'
    const successText = 'Zpráva byla úspěšně odeslána. Děkujeme.'
    const errorTextWentWrong = "Něco se pokazilo. Zkuste to prosím znovu zítra."
    const [emailBtnColor, setEmailBtnColor] = useState('var(--secondary)')
    const [errorText, setErrorText] = useState('')
    const [sending, setSending] = useState(false)
    let timoutId = null
    const MAX_MESSAGE_LENGTH = 500

    const showMessage = (message) => {
        setErrorText(message)
        timoutId && clearTimeout(timoutId)
        timoutId = setTimeout(() => setErrorText(''), 10000)
    }
    const blink = (color) => {
        setEmailBtnColor(color)
        setTimeout(() => setEmailBtnColor('var(--secondary)'), 500)
    }

    const sendMailHandler = () => {
        if (!validateEmail(email)) {
            showMessage(errorTextAddress)
            blink('var(--warning)')
            return
        }
        if (name === "" || textMessage === "") {
            showMessage(errorTextFields)
            blink('var(--warning)')
            return
        }

        const html = `
                <div>
                <p>${textMessage.slice(0, MAX_MESSAGE_LENGTH)}</p>
                <p>From: ${name}</p>
                <p>E-mail from: ${email}</p>
                <p>Phone: ${userStore.user?.phone || ''}</p>
                </div>`
        //<a href={"mailto: ${email}"}>${email}</a></p></div>`

        const fd = new FormData()
        fd.append("html", html)
        fd.append("isMassageFromUser", "true")

        setSending(true)
        sendEmail(fd)
            .then(() => {
                setName(userStore.user?.name || '')
                setEmail(userStore.user?.email || '')
                showMessage(successText)
                blink('var(--secondary-60)')
            })
            .catch(e => {
                const [errCode,] = e.split(':') || ['']
                switch (errCode) {
                    case 'TooOften':
                        showMessage(errorTextTooOften);
                        break
                    case 'TooMany':
                        showMessage(errorTextTryTomorrow);
                        break
                    default:
                        showMessage(errorTextWentWrong);
                        break
                }
                blink('var(--warning)')
            })
            .finally(() => setSending(false))
    }

    function validateEmail(email) {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Container fluid className="px-md-0">

            <div className="shopSectionName">
                Kde nás najdete
            </div>
            <div className="d-block d-md-flex justify-content-md-between justify-content-center w-100">
                <div className={styles.c1}>
                    <div className={styles.c11}>{infoStore.firm.name}</div>
                    <div className={styles.c12}>
                        <img src={iconAddress} alt="Icon" className="me-1"/>
                        {infoStore.firm.address.street}, {infoStore.firm.address.city}
                    </div>
                    <div className={styles.c13}>
                        <img src={iconEmail} alt="Icon" className="me-1 mb-1"/>
                        <a href={`mailto: ${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
                    </div>
                    <div className={styles.c14}>Otevírací doba</div>
                    <div className={styles.c12}>{infoStore.openDays}</div>
                    <div className={styles.c12}>{infoStore.openHours}</div>
                </div>

                <div className={styles.c3}>
                    {/*<MapComponent width={280} height={252}/>*/}
                    <GoogleMapHTML width={300} height={252}/>
                </div>

                <div className={styles.c2}>
                    <div className={styles.name}>
                        Máte dotaz? Napište nám
                    </div>
                    <input className={styles.c21}
                           type="text"
                           placeholder="Jméno"
                           value={name}
                           onChange={e => setName(e.target.value)}/>
                    <input className={styles.c21}
                           type="email"
                           placeholder="Email"
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                    />
                    <textarea className={styles.msgText} placeholder="Zpráva"
                              value={textMessage}
                              onChange={e =>
                                  setTextMessage(e.target.value.length< MAX_MESSAGE_LENGTH ? e.target.value : textMessage)}
                    />

                    <div className="">
                        <div className="d-flex w-100 justify-content-md-end justify-content-center">
                            {!sending ?
                                <div className={styles.c24Button}
                                     style={{backgroundColor: emailBtnColor}}
                                     onClick={sendMailHandler}>
                                    Odeslat
                                </div>
                                :
                                <div className={styles.c24Button}>
                                    <Spinner size="sm"/>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-end">
                        <div className={styles.c24Messege}
                             style={{
                                 color: errorText === successText ?
                                     'var(--secondary)' : 'var(--warning)'
                             }}>
                            {errorText}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
        ;
});

export default Contacts;